
import store from '@/store'

export const base_url = 'https://uniccoimcp.unicco.com.mx/index.php/api';

export const services = {
    token                       : () => `/udirecto/token`, // 001
    valida_rfc_sat              : () => `/udirecto/valida-rfc-sat`, // 002
    valida_rfc_revolvente       : () => `/udirecto/valida-rfc-revolvente`, // 003
    validar_clabe               : () => `/udirecto/validar-clabe`, // 007
    postal                      : () => `/udirecto/postal`, // 008
    abrir_cuenta                : () => `/udirecto/abrir-cuenta`, // 011
    validar_cuenta              : () => `/udirecto/validar-cuenta`, // 012
    reenvio_sms_cuenta          : () => `/udirecto/reenvio-sms-cuenta`, // 013
    consulta_banco              : () => `/udirecto/consulta-banco`, // 021
    regeneracion_sms            : () => `/udirecto/regeneracion-sms`, // 022
    revalidacion_sms            : () => `/udirecto/revalidacion-sms`, // 023
    envio_rfc_contrasena        : () => `/udirecto/envio-rfc-contrasena`, // 026
    validar_codigo_contrasena   : () => `/udirecto/validar-codigo-contrasena`, // 027
    formulario                  : () => `/udirecto/formulario`, // 028
    obtener_formulario          : () => `/udirecto/obtener-formulario`, // 029
    codigo_promocional          : () => `/udirecto/codigo-promocional`, // 030
    lista_formulario            : () => `/udirecto/lista-formulario`, // 031
    datos_persona               : () => `/udirecto/datos-persona`, // 032
    empresa_producto_cliente    : () => `/udirecto/empresa-producto-cliente`, // 033
    formulario_token_solicitud  : () => `/udirecto/formulario-token-solicitud`, // 034
    formulario_autoguardado     : () => `/udirecto/formulario-autoguardado`, // 035
    formulario_guardado         : () => `/udirecto/formulario-guardado`, // 036
    formulario_actualizado      : () => `/udirecto/formulario-actualizado`, // 036
    codigo_monto                : () => `/udirecto/codigo-monto`, // 037
    formulario_dato             : () => `/udirecto/formulario-dato`, // 038
    formulario_consulta         : () => `/udirecto/formulario-consulta`, // 038
    licitacion                  : () => `/udirecto/licitacion`, // 040
    documento_legal_codigo      : () => `/udirecto/documento-legal-codigo`, // 040
    documento_formulario        : () => `/udirecto/documento-formulario`, // 042
    datos_comprobante           : () => `/udirecto/datos-comprobante`, // 043
    form_documento_guardado     : () => `/udirecto/formulario-documento-guardado`, // 044
    documento_solicitud         : () => `/udirecto/documento-solicitud`, // 044
    firma                       : () => `/udirecto/firma`, // 045
    generar_documento_legal     : () => `/udirecto/generar-documento-legal`, // 047
    actualizar_documento        : () => `/udirecto/actualizar-documento`, // 048
    datos_sesion                : () => `/udirecto/datos-sesion`, // 049
    comunicacion_home           : () => `/udirecto/comunicacion-home`, // 051
    documento_error             : () => `/udirecto/documento-error`, // 051
    comunicacion_sesion         : () => `/udirecto/comunicacion-sesion`, // 052
    refinanciamiento            : () => `/udirecto/refinanciamiento`, // 053
    staff                       : () => `/udirecto/staff`, // 054
    codigo_refinanciamiento     : () => `/udirecto/codigo-refinanciamiento`, // 056
    contacto                    : () => `/udirecto/contacto`, // 057
    valida_edad                 : () => `/udirecto/valida-edad`, // 060
    valida_edad_curp            : () => `/udirecto/valida-edad-curp`, // 061
    valida_edad_rfc             : () => `/udirecto/valida-edad-rfc`, // 062
    recaptcha                   : () => `/udirecto/recaptcha`,
}

export const fetchJS = async (
    {
        url, 
        method, 
        headers, 
        body, 
        mode,
        credentials,
        redirect
    }, auth = true, stringify = false) => {

    const object = {};

    (method) && (object.method = method);
    (body) && (object.body = body);
    (mode) && (object.mode = mode);
    (credentials) && (object.credentials = credentials);
    (redirect) && (object.redirect = redirect);
    
    let token = ''
    if (auth) {
        token = store.getters.user.token;
    }

    const _headers =  headers ?? { 
        "Accept" : "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
    }

    auth && ( _headers['Authorization'] = `Bearer ${token}`)
    object.headers = _headers

    if (body && !stringify) {
        const urlencoded = new URLSearchParams();
        Object.keys(body).forEach(key =>{
            urlencoded.append(key, body[key]);
        });

        object.body = urlencoded
    }

    (body && stringify) && (object.body = body)
    
    const response = await fetch(base_url + url, object);
    const jsonResponse = await response.json();

    const isAuthenticated = store.getters.isAuthenticated;
    if (isAuthenticated && response.status == 401) {
        store.dispatch('logout');
        document.location.href = "/";
    }

    return {
        'status' : response.status,
        'data' : jsonResponse 
    }

}
